<template>
  <v-dialog
    v-model="show"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar :color="options.color" dark>
        <v-toolbar-title class="white--text"> Wybierz format pliku </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row>
          <v-col>
            <v-btn block elevation="2" @click.native="agree('pdf')"> Plik PDF </v-btn>
          </v-col>

          <v-col>
            <v-btn block elevation="2" @click.native="agree('csv')"> Plik CSV </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-btn @click.native="cancel" color="grey" text>
          {{ options.cancelButtonText }}
        </v-btn>

        <v-spacer></v-spacer>

        <!-- <v-btn @click.native="agree" color="primary darken-1" text>
          {{ options.acceptButtonText }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'FileFormatDialog',
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      options: {
        color: 'black',
        width: 290,
        zIndex: 200,
        cancelButtonText: 'Anuluj',
        // acceptButtonText: 'Zatwierdź',
      },
    }),
    computed: {
      show: {
        get() {
          return this.dialog;
        },
        set(value) {
          this.dialog = value;
          if (value === false) this.cancel();
        },
      },
    },
    methods: {
      open() {
        this.dialog = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree(fileType) {
        this.resolve(fileType);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>
